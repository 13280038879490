<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-widget-right">
                    <iframe src=" https://leads.avitrons.com/getlead.php " width="100%" height="500" frameborder="0" scrolling="auto"></iframe>


                    <div class="contact-widget">
                        <h2>Contact Info</h2>
                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:+17633103333">+1 763 310 3333</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:info@avitrons.com">info@avitrons.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>4810 Ranchview Ln N Plymouth,<br> MN 55446</span>
                                </div>
                            </li>
                        </ul>
                    </div> 
                    <div class="widget-category">
                        <h3>Other Services</h3>
                        <ul>
                                <li class="nav-item"><a routerLink="/webdevelopment">Web Development</a></li>
                                <li class="nav-item"><a routerLink="/appdevelopment" >App Development</a></li>
                                <li class="nav-item"><a routerLink="/digitalmarketing">Digital Marketing</a></li>
                                <li class="nav-item"><a routerLink="/seo">Search Engine Optimization</a></li>
                                <li class="nav-item"><a routerLink="/smm">Social Media Marketing</a></li>
                                <li class="nav-item"><a routerLink="/graphicdesigning">Graphic Designing</a></li>
                        </ul>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>



