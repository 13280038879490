<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Social Media Marketing</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Social Media Marketing</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/seo.jpg" alt="Images">
                        <h2>Content Marketing</h2>
                        <p>Social media marketing is an online marketing method that utilizes different social media platforms, such as Facebook, Twitter, LinkedIn and Pinterest, to establish brand recognition, capture customers’ attention, and connect brands with a broader, more diverse audience segment. Social media marketing for small businesses and enterprises is a powerful way to reach your prospects right where they spend their time online and bolster your brand engagement.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Facebook</h3>
                                <p>Facebook is the most popular online social media platform that provides highly targeted paid ads as well as organic posts, enabling brands to showcase their product or services in front of targeted customers or audiences.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Twitter</h3>
                                <p>Twitter is a free social media platform where users broadcast posts known as tweets. These tweets consist of text, videos, photos, or links. Twitter helps to build community interest for its brand and generate leads.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Instagram</h3>
                                <p>Instagram empowers brands or companies or individuals to promote their products or services on its platform. Instagram assists brands to connect with a vast audience, increasing brand awareness, and maximising sales.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>LinkedIn</h3>
                                <p>Linkedin is a social media platform that is used by top professionals and companies. LinkedIn helps businesses to make connections, generate leads, improve brand awareness, and many more.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For Content Marketer</h2>  
                                    <ul>
                                        <li><i class='bx bx-check'></i> Cost Effective</li>
                                        <li><i class='bx bx-check'></i> Increase Brand Awareness</li>
                                        <li><i class='bx bx-check'></i> Build Credibility & Authority </li>
                                        <li><i class='bx bx-check'></i> Boost Website Traffic </li>
                                        <li><i class='bx bx-check'></i> Increase Sales </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Social media marketing helps you to connect with your audience and assist them to understand your brand better. It is very useful to your business growth.</p>

                    <!-- <div class="services-widget-list">
                        <ul>
                            <li class="active">
                                <span>75%</span>
                                <div class="content list1">
                                    <h3>SEO & Marketing</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Keywords Results</h3>
                                </div>
                                <span>66%</span>
                            </li>
                            <li>
                                <span>43%</span>
                                <div class="content list3">
                                    <h3>Google Analytics</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Off Page SEO</h3>
                                </div>
                                <span>15%</span>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                   <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>