<app-navbar-style-two></app-navbar-style-two>

<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content-two">
                        <span>Best for You</span>
                    <h1>We <b>Developing</b> The Future With Tech</h1>
                          <div class="banner-content-btn">
                            <a routerLink="/contact" class="learn-btn">Learn More <i class='bx bx-plus'></i></a>
                            <!-- <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-on-btn popup-btn">Play Video <i class='flaticon-forward'></i></a> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>
<div class="service-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">About Us</span>
            <h2>Leveraging tech to drive an exceptional IT experience</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/about">Mission</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/about"><img src="assets/img/service/service-icon7.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Being professional digital marketing, mobile application, and web development company. Our mission is to provide customer-centric, result-oriented, cost-competitive innovative & functional IT Solutions to our valuable global clients.</p>
                    <a routerLink="/about" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/about">Vision</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/about"><img src="assets/img/service/service-icon8.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>At Avitron Marke - We Focus on constant innovation as our key to achieving the ultimate goal of success and emerging as a globally recognized company by providing superior quality services and solutions to our valuable clients.</p>
                    <a routerLink="/about" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

           
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">About Us</span>
                            <h2>Build Your <span>Business</span> With Us</h2>
                        </div>
                        <p>Avitron Marke is a digital marketing and website development services provider, from USA who has been a leader in the concerned field for years. We have achieved excellent results in the field of digital marketing and with our reliable and hardworking staff, we are marching ahead relentlessly! Our proven results make us the number one choice for anyone aiming to rule the online business world!</p>
                        
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>01</span>
                                    <p>One-Stop Solution</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>02</span>
                                    <p>Experience Professional Teams</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>03</span>
                                    <p>Increased Productivity</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>06</span>
                                    <p>Excellent Support</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>04</span>
                                    <p>Real-Time Optimisation</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>05</span>
                                    <p>Proactive Monitoring & Maintenance</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>5 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>25+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>7+</h3>
                    <p>SENIOR Developer</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>

<div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>We Offer Professional Solutions For Your Business</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/webdevelopment"><i class="flaticon-research service-icon service-icon-bg1"></i></a>
                    <h3><a routerLink="/webdevelopment">Web Development</a></h3>
                    <p>Our web development services assess to create all types of websites, web-based software, and applications and ensure a world-class experience for users. At Avitron, we competently design, redesign, and support customer-facing ..</p>
                    <a routerLink="/webdevelopment" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/appdevelopment"><i class="flaticon-headphones service-icon service-icon-bg"></i></a>
                    <h3><a routerLink="/appdevelopment">App Development</a></h3>
                    <p>Avitron develops cross-platform and hybrid applications to assess business target multiple platforms using the same code base for iOS, Android, and Windows platforms. We offer multi-purpose cross-platform mobile applications ..
                    </p>
                    <a routerLink="/appdevelopment" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/digitalmarketing"><i class="flaticon-caution service-icon service-icon-bg2"></i></a>
                    <h3><a routerLink="/digitalmarketing">Digital Marketing</a></h3>
                    <p>We have top-notch digital marketing professionals. They are experts in bringing leads, consistent growth, increased ranking, website tracking, and visits, boosted client engagement, and so much more.</p>
                    <a routerLink="/digitalmarketing" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/seo"><i class="flaticon-big-data service-icon service-icon-bg3"></i></a>
                    <h3><a routerLink="/seo">Search Engine Optimisation</a></h3>
                    <p>Search Engine Optimisation (SEO) is the way to increase a website’s position on the search results page, boosting visibility organically. An effective SEO requires to be part of your overall digital activation as it impacts every aspect of your digital presence. Our SEO experts use the latest SEO techniques,  to increase your organic ..
                    </p>
                    <a routerLink="/seo" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/smm"><i class="flaticon-money service-icon service-icon-bg4"></i></a>
                    <h3><a routerLink="/smm">Social Media Marketing</a></h3>
                    <p>We, Avitron, a professional social media marketing company provide you with the ultimate solution to all of your social media marketing problems. Our social media marketers will asses to expand your reach, maximize your web presence, increase traffic, build your brand image, and help you make connections ..</p>
                    <a routerLink="/smm" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/graphicdesigning"><i class="flaticon-share service-icon service-icon-bg5"></i></a>
                    <h3><a routerLink="/graphicdesigning">Graphic Designing</a></h3>
                    <p>Avitron offers professional graphic design service in India. Our creative graphic designing solutions include brochures, banners, flyers, business cards, logos, e-books, etc. Our graphic designing experts have stunning creativity skills and develop amazing and eye caching creatives, flyers, etc.</p>
                    <a routerLink="/graphicdesigning" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Easy and Immaculate Work Process
            </h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Define -<span>1</span></h3>
                            <p>This document is called Software Requirement Specification (SRS).</p>
                        </li>
                        <li class="text-end">
                            <h3>Design -<span>2</span></h3>
                            <p>In this phase, we use SRS as a reference for product or software design.</p>
                        </li>
                        <li class="text-end">
                            <h3>Develop -<span>3</span></h3>
                            <p>In this phase, the development starts and every developer will follow the SRS and DDS.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Testing -</h3>
                            <p>Until the products meet the original specifications and best quality.</p>
                        </li>
                        <li>
                            <h3><span>5</span>Plan & Identify Requirements</h3>
                            <p>Planning is the most important thing to do before starting a project.</p>
                        </li>
                        <li>
                            <h3><span>6</span>Deployment -</h3>
                            <p>In the end phase, we deploy the product or software when it completed the testing phases and we make it available to users.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="testimonial-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 text-center py-5">
                <!-- <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div> -->
                 <img src="assets/img/testimonial/review.png" alt="Images">
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>What our client says</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>Avitron and the team put a lot of work and thoughts in to the solution that I asked them to create for me. I really appreciate the effort.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>David McLean</h3>
                                <!-- <span>CEO & Manager</span> -->
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I have ordered for a website and that was delivered best in time.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Jermin Jekson</h3>
                                <!-- <span>Marketing Manager</span> -->
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>Professional staff and straight forward working culture. They help me in various fields. Professional IT company in USA</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Julfiker Jeain</h3>
                                <!-- <span>CEO At Ostino</span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Blogs</span>
            <h2 class="h2-color2">LATEST BLOGS</h2>
            <p class="margin-auto">The Avitron’s Guide A simple way to stay tuned with the latest digital solutions and updates.</p>
        </div>

        <div class="row pt-45">


            <div class="col-lg-4 col-md-6" *ngFor="let post of blogPosts | slice:0:3">
                 <div class="blog-card">
                    <a [routerLink]="['/posts', post.slug]"><img [src]="post.fimg_url"  alt="Images"></a>
                    
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3 ><a [routerLink]="['/posts', post.slug]" class="post-title" [innerHTML]="post.title.rendered "></a></h3>
                        <p [innerHTML]="post.excerpt.rendered | slice:0:400 ">
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<div class="contact-ection pt-100 pb-70">
    <div class="container-fluid">
     <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="data-table-img">
            <img src="assets/img/contact-img.png" alt="Images">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
          <div class="contact-wrap">
            <iframe src=" https://leads.avitrons.com/getlead.php " width="100%" height="500" frameborder="0" scrolling="auto"></iframe>

          </div>
      </div>
     </div>
    </div>
</div>

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>We Like to Start Your Project With Us</h2>
                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/data-table-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>





<app-footer-style-two></app-footer-style-two>